import { Logo } from '../logo/logo';
import './spinner.scss';

export function Spinner() {
  return (
    <div className="spinner">
      <Logo />
    </div>
  );
}

export default Spinner;
