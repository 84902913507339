import {
  Action,
  configureStore,
  Middleware,
  ThunkAction,
} from '@reduxjs/toolkit';

import {
  authenticationApi,
  baseApi,
  customViewsReducer,
  loginReducer,
} from '@transova/rtk';

import spinnerReducer from '../features/spinner/spinnerSlice';
import { spinner } from '../middleware/spinner';
import {
  cardViewReducer,
  currentRouteReducer,
  quickFilterReducer,
} from '../reducers/';

export const rtkQuerySpinner: Middleware = spinner;

export const store = configureStore({
  reducer: {
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    isCardView: cardViewReducer,
    currentRoute: currentRouteReducer,
    currentView: customViewsReducer,
    login: loginReducer,
    spinner: spinnerReducer,
    quickFilter: quickFilterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authenticationApi.middleware,
      baseApi.middleware,
      rtkQuerySpinner,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
