import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface ICalvesAnticipatedState {
  transferDate?: Date;
  donor?: string;
  earTag?: string;
  rfid?: string;
  method?: string;
  sire?: string;
  fetalSex?: string;
  recipNum?: string;
  estCalfDate?: Date;
  confidential?: string;
}

export const initialCalvesAnticipatedState: ICalvesAnticipatedState = {
  transferDate: undefined,
  donor: undefined,
  earTag: undefined,
  rfid: undefined,
  method: undefined,
  sire: undefined,
  fetalSex: undefined,
  recipNum: undefined,
  estCalfDate: undefined,
};

export const initialCalvesAnticipatedColDefs: ColDefs<ICalvesAnticipatedState> =
  [
    {
      headerName: 'Transfer Date',
      field: 'transferDate',
      initialWidth: 132,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    { headerName: 'Donor', field: 'donor', initialWidth: 116 },
    { headerName: 'Ear Tag', field: 'earTag', initialWidth: 99 },
    { headerName: 'RFID/Metal Tag', field: 'rfid', initialWidth: 144 },
    { headerName: 'Method', field: 'method', initialWidth: 103 },
    { headerName: 'Sire', field: 'sire', initialWidth: 151 },
    { headerName: 'Fetal Sex', field: 'fetalSex', initialWidth: 108 },
    { headerName: 'Recip #', field: 'recipNum', initialWidth: 100 },
    {
      headerName: 'Est Calf Date',
      field: 'estCalfDate',
      initialWidth: 129,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    {
      headerName: 'CONFIDENTIAL',
      field: 'confidential',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

export const calvesAnticipatedExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Calves Anticipated',
  fileName: 'Calves Anticipated',
  columnKeys: [
    'confidential',
    'transferDate',
    'donor',
    'earTag',
    'rfid',
    'method',
    'sire',
    'fetalSex',
    'recipNum',
    'estCalfDate',
  ],
};
export const calvesAnticipatedCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Calves Anticipated',
  fileName: 'Calves Anticipated',
};

export const calvesAnticipatedPdfExport = {
  fileName: 'Calves Anticipated',
  columnKeys: [
    'transferDate',
    'donor',
    'earTag',
    'rfid',
    'method',
    'sire',
    'fetalSex',
    'recipNum',
    'estCalfDate',
  ],
};

export const calvesAnticipatedSlice = createSlice({
  name: 'calvesAnticipated',
  initialState: initialCalvesAnticipatedState,
  reducers: {
    setCalvesAnticipated: (
      state: ICalvesAnticipatedState,
      action: PayloadAction<ICalvesAnticipatedState>,
    ) => {
      state.transferDate = action.payload.transferDate;
      state.donor = action.payload.donor;
      state.earTag = action.payload.earTag;
      state.rfid = action.payload.rfid;
      state.method = action.payload.method;
      state.sire = action.payload.sire;
      state.fetalSex = action.payload.fetalSex;
      state.recipNum = action.payload.recipNum;
      state.estCalfDate = action.payload.estCalfDate;
    },
  },
});

export const { setCalvesAnticipated } = calvesAnticipatedSlice.actions;

export default calvesAnticipatedSlice.reducer;
