import { dateFormatter, filterParams } from '@transova/rtk';
import { ColDefs } from '@transova/utilities';

import { gridOptions } from '../grid/defaults';

export interface IEmbryoInventoryAllState {
  embryoId: string;
  cane?: string;
  donor?: string;
  donorBreed?: string;
  embryoCreation?: Date;
  embryoName?: string;
  fertSite?: string;
  freezeMethod?: string;
  grade?: number;
  method?: string;
  paNm?: number;
  paTpi?: number;
  received?: Date;
  regNum?: string;
  sex?: string;
  shipmentNum?: string;
  sire?: string;
  sireBreed?: string;
  sireId?: string;
  siteId?: string;
  stage?: number;
  storageLocation?: string;
  storageLocationSiteId?: string;
  straw?: number;
  soldTo: string | null;
  shipToFBD: string | null;
  semenType: string;
  export: string;
  embryoStatus: string | null;
  shipmentStatus: string | null;
  confidential?: string;
}

export const initialEmbryoInventoryAllColDefs: ColDefs<IEmbryoInventoryAllState> =
  [
    { headerName: 'Site', field: 'siteId', initialWidth: 241 },
    { headerName: 'Embryo Name', field: 'embryoName', initialWidth: 231 },
    { headerName: 'Freeze Method', field: 'freezeMethod', initialWidth: 142 },
    { headerName: 'Donor', field: 'donor', initialWidth: 158 },
    { headerName: 'Reg #', field: 'regNum', initialWidth: 156 },
    { headerName: 'Sire', field: 'sire', initialWidth: 123 },
    { headerName: 'Sire Id/NAAB', field: 'sireId', initialWidth: 132 },
    {
      headerName: 'Sex',
      field: 'sex',
      initialWidth: 82,
    },
    { headerName: 'Donor Breed', field: 'donorBreed', initialWidth: 129 },
    { headerName: 'Sire Breed', field: 'sireBreed', initialWidth: 115 },
    {
      headerName: 'Storage Location',
      field: 'storageLocation',
      initialWidth: 152,
    },
    { headerName: 'Cane', field: 'cane', initialWidth: 103 },
    {
      headerName: 'Straw',
      field: 'straw',
      filter: 'agNumberColumnFilter',
      initialWidth: 90,
    },
    {
      headerName: 'Grade',
      field: 'grade',
      filter: 'agNumberColumnFilter',
      initialWidth: 93,
    },
    {
      headerName: 'Stage',
      field: 'stage',
      filter: 'agNumberColumnFilter',
      initialWidth: 90,
    },
    {
      headerName: 'Embryo Creation',
      field: 'embryoCreation',
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
      initialWidth: 152,
    },
    { headerName: 'Fert Site', field: 'fertSite', initialWidth: 241 },
    { headerName: 'Shipment #', field: 'shipmentNum', initialWidth: 123 },
    {
      headerName: 'Received',
      field: 'received',
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
      initialWidth: 108,
    },
    { headerName: 'Method', field: 'method', initialWidth: 103 },
    { headerName: 'Semen Type', field: 'semenType', initialWidth: 110 },
    { headerName: 'Sold To', field: 'soldTo', initialWidth: 100 },
    { headerName: 'Export', field: 'export', initialWidth: 100 },
    {
      headerName: 'CONFIDENTIAL',
      field: 'confidential',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

export const embryoInventoryAllExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Embryo Inventory (All)',
  fileName: 'Embryo Inventory (All)',
  columnKeys: [
    'confidential',
    'siteId',
    'embryoName',
    'freezeMethod',
    'donor',
    'regNum',
    'sire',
    'sireId',
    'sex',
    'donorBreed',
    'sireBreed',
    'storageLocation',
    'cane',
    'straw',
    'grade',
    'stage',
    'embryoCreation',
    'fertSite',
    'shipmentNum',
    'received',
    'method',
    'semenType',
    'soldTo',
    'export',
  ],
};
export const embryoInventoryAllCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Embryo Inventory (All)',
  fileName: 'Embryo Inventory (All)',
};

export const embryoInventoryAllPdfExport = {
  fileName: 'Embryo Inventory (All)',
  columnKeys: [
    'siteId',
    'embryoName',
    'freezeMethod',
    'donor',
    'regNum',
    'sire',
    'sireId',
    'sex',
    'donorBreed',
    'sireBreed',
    'storageLocation',
    'cane',
    'straw',
    'grade',
    'stage',
    'embryoCreation',
    'fertSite',
    'shipmentNum',
    'received',
    'method',
    'semenType',
    'soldTo',
    'export',
  ],
};
