import { createSlice } from '@reduxjs/toolkit';

export const quickFilterSlice = createSlice({
  name: 'quickFilter',
  initialState: '',
  reducers: {
    getQuickFilter: (state: string) => state,
    setQuickFilter: (state, action) => {
      state = action.payload.toLowerCase();

      return state;
    },
  },
});

export const { getQuickFilter, setQuickFilter } = quickFilterSlice.actions;

export default quickFilterSlice.reducer;
