import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import { gridOptions } from '../grid/defaults';
import {
  useCurrentAccountCode,
  useFrozenShipmentReportMutation,
} from '../services';

export interface IFrozenShipmentsState {
  containsEmbryos?: boolean;
  containsSemen?: boolean;
  createdOn?: Date;
  ranchId?: string;
  shipCity?: string;
  shipDate?: Date;
  shipmentNum?: string;
  shipName?: string;
  shipState?: string;
  confidential?: string;
  PDF?: string;
}

export const initialFrozenShipmentsState: IFrozenShipmentsState = {
  containsEmbryos: undefined,
  containsSemen: undefined,
  createdOn: undefined,
  ranchId: undefined,
  shipCity: undefined,
  shipDate: undefined,
  shipmentNum: undefined,
  shipName: undefined,
  shipState: undefined,
};

const PdfRenderer = ({ data }: ICellRendererParams<IFrozenShipmentsState>) => {
  const menu = useRef<Menu>(null);
  const [report] = useFrozenShipmentReportMutation();
  const [accountCode] = useCurrentAccountCode();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!accountCode || !data?.shipmentNum) {
                return;
              }

              const res = await report({
                accountCode,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!accountCode || !data?.shipmentNum) {
                return;
              }

              const res = await report({
                accountCode,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [accountCode, data?.shipmentNum, report],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

const CheckmarkIfTrueRenderer = (props: ICellRendererParams) => {
  return (
    <span className="custom-cell__checkmark">{props.value ? '✓' : ''}</span>
  );
};

const collator = Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});
export const initialFrozenShipmentsColDefs: ColDefs<IFrozenShipmentsState> = [
  {
    headerName: 'PDF',
    field: 'PDF',
    headerClass: 'header-center',
    cellRenderer: PdfRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
    initialWidth: 66,
  },
  {
    headerName: 'Shipment #',
    field: 'shipmentNum',
    comparator: (valueA, valueB) => {
      return collator.compare(valueA, valueB);
    },
    initialSort: 'desc',
    initialWidth: 123,
  },
  {
    headerName: 'Created On',
    field: 'createdOn',
    valueFormatter: dateFormatter,
    filterParams,
    filter: 'agDateColumnFilter',
    initialWidth: 122,
  },
  {
    headerName: 'Ship Date',
    field: 'shipDate',
    valueFormatter: dateFormatter,
    filterParams,
    filter: 'agDateColumnFilter',
    initialWidth: 113,
  },
  { headerName: 'Ship Name', field: 'shipName', initialWidth: 176 },
  { headerName: 'Ship City', field: 'shipCity', initialWidth: 123 },
  { headerName: 'Ship State', field: 'shipState', initialWidth: 115 },
  {
    headerName: 'Contains Embryos',
    field: 'containsEmbryos',
    cellRenderer: CheckmarkIfTrueRenderer,
    initialWidth: 158,
    cellStyle: { display: 'grid', placeContent: 'center' },
  },
  {
    headerName: 'Contains Semen',
    field: 'containsSemen',
    initialWidth: 148,
    cellRenderer: CheckmarkIfTrueRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
  },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const frozenShipmentsExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Frozen Shipments',
  fileName: 'Frozen Shipments',
  columnKeys: [
    'confidential',
    'shipmentNum',
    'createdOn',
    'shipDate',
    'shipName',
    'shipCity',
    'shipState',
    'containsEmbryos',
    'containsSemen',
  ],
};
export const frozenShipmentsCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Frozen Shipments',
  fileName: 'Frozen Shipments',
};

export const frozenShipmentsPdfExport = {
  fileName: 'Frozen Shipments',
  columnKeys: [
    'shipmentNum',
    'createdOn',
    'shipDate',
    'shipName',
    'shipCity',
    'shipState',
    'containsEmbryos',
    'containsSemen',
  ],
};

export const frozenShipmentsSlice = createSlice({
  name: 'frozenShipments',
  initialState: initialFrozenShipmentsState,
  reducers: {
    setFrozenShipments: (
      state: IFrozenShipmentsState,
      action: PayloadAction<IFrozenShipmentsState>,
    ) => {
      state.shipmentNum = action.payload.shipmentNum;
      state.createdOn = action.payload.createdOn;
      state.shipDate = action.payload.shipDate;
      state.shipName = action.payload.shipName;
      state.shipCity = action.payload.shipCity;
      state.shipState = action.payload.shipState;
      state.containsEmbryos = action.payload.containsEmbryos;
      state.containsSemen = action.payload.containsSemen;
    },
  },
});

export const { setFrozenShipments } = frozenShipmentsSlice.actions;

export default frozenShipmentsSlice.reducer;
