import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dateFormatter } from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface IEmbryoInventoryByMatingDetailState {
  cane?: string;
  donor?: string;
  earTag?: string;
  freezeDate?: Date;
  grade?: number;
  location?: string;
  quantity?: number;
  semenType?: string;
  sire?: string;
  soldTo?: string;
  stage?: number;
  straw?: number;
}

export interface IEmbryoInventoryByMatingState {
  breed?: string;
  donor?: string;
  embryoName?: string;
  earTag?: string;
  sire?: string;
  quantity?: number;
  tankLocation?: string;
  method?: string;
  semenType?: string;
  soldTo?: string;
  export?: string;
  detail?: IEmbryoInventoryByMatingDetailState;
  confidential?: string;
}

export const initialEmbryoInventoryByMatingState: IEmbryoInventoryByMatingState =
  {
    breed: undefined,
    donor: undefined,
    earTag: undefined,
    embryoName: undefined,
    sire: undefined,
    quantity: undefined,
    tankLocation: undefined,
    method: undefined,
    semenType: undefined,
    soldTo: undefined,
    export: undefined,
    detail: undefined,
  };

export const initialEmbryoInventoryByMatingColDefs: ColDefs<IEmbryoInventoryByMatingState> =
  [
    {
      headerName: 'Donor',
      field: 'donor',
      initialWidth: 155,
      cellRenderer: 'agGroupCellRenderer',
    },
    { headerName: 'Ear Tag', field: 'earTag', initialWidth: 109 },
    { headerName: 'Embryo Name', field: 'embryoName', initialWidth: 155 },
    { headerName: 'Breed', field: 'breed', initialWidth: 92 },
    { headerName: 'Sire', field: 'sire', initialWidth: 123 },
    { headerName: 'Semen Type', field: 'semenType', initialWidth: 126 },
    { headerName: 'Method', field: 'method', initialWidth: 103 },
    { headerName: 'Location', field: 'tankLocation', initialWidth: 107 },
    {
      headerName: 'Quantity',
      field: 'quantity',
      filter: 'agNumberColumnFilter',
      initialWidth: 107,
    },
    { headerName: 'Sold To', field: 'soldTo', initialWidth: 318 },
    { headerName: 'Export', field: 'export', initialWidth: 96 },
    { headerName: 'Detail', field: 'detail', hide: true },
    {
      headerName: 'CONFIDENTIAL',
      field: 'confidential',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

export const initialEmbryoInventoryByMatingDetailColDefs: ColDefs<IEmbryoInventoryByMatingDetailState> =
  [
    { field: 'freezeDate', valueFormatter: dateFormatter },
    { field: 'quantity' },
    { field: 'straw' },
    { field: 'cane' },
    { field: 'stage' },
    { field: 'grade' },
    { field: 'location' },
    { field: 'sire' },
    { field: 'donor' },
    { field: 'soldTo' },
    { field: 'earTag' },
    { field: 'semenType' },
  ];

export const embryoInventoryByMatingExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Embryo Inventory (ByMating)',
  fileName: 'Embryo Inventory (ByMating)',
  columnKeys: [
    'confidential',
    'donor',
    'earTag',
    'embryoName',
    'breed',
    'sire',
    'semenType',
    'method',
    'tankLocation',
    'quantity',
    'soldTo',
    'export',
  ],
};
export const embryoInventoryByMatingCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Embryo Inventory (ByMating)',
  fileName: 'Embryo Inventory (ByMating)',
};

export const embryoInventoryByMatingPdfExport = {
  fileName: 'Embryo Inventory (ByMating)',
  columnKeys: [
    'breed',
    'donor',
    'embryoName',
    'earTag',
    'sire',
    'quantity',
    'tankLocation',
    'method',
    'semenType',
    'soldTo',
    'export',
  ],
};

export const embryoInventoryByMatingSlice = createSlice({
  name: 'embryoInventoryByMating',
  initialState: initialEmbryoInventoryByMatingState,
  reducers: {
    setEmbryoInventoryByMating: (
      state: IEmbryoInventoryByMatingState,
      action: PayloadAction<IEmbryoInventoryByMatingState>,
    ) => {
      state.breed = action.payload.breed;
      state.donor = action.payload.donor;
      state.embryoName = action.payload.embryoName;
      state.earTag = action.payload.earTag;
      state.sire = action.payload.sire;
      state.quantity = action.payload.quantity;
      state.tankLocation = action.payload.tankLocation;
      state.method = action.payload.method;
      state.semenType = action.payload.semenType;
      state.soldTo = action.payload.soldTo;
      state.export = action.payload.export;
      state.detail = action.payload.detail;
    },
  },
});

export const { setEmbryoInventoryByMating } =
  embryoInventoryByMatingSlice.actions;

export default embryoInventoryByMatingSlice.reducer;
