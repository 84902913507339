import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import { gridOptions } from '../grid/defaults';
import {
  useCurrentAccountCode,
  useFreshShipmentReportMutation,
  useImplantFormReportMutation,
} from '../services';

export interface IFreshShipmentsState {
  createdOn?: Date;
  matings?: number;
  name?: string;
  shipmentNum?: string;
  shipperId?: string;
  trackingNum?: string;
  confidential?: string;
  shipmentForm?: string;
  embryoCollectionForm?: string;
}

export const initialFreshShipmentsState: IFreshShipmentsState = {
  createdOn: undefined,
  matings: undefined,
  name: undefined,
  shipmentNum: undefined,
  shipperId: undefined,
  trackingNum: undefined,
};

const ShipmentPdfRenderer = ({
  data,
}: ICellRendererParams<IFreshShipmentsState>) => {
  const menu = useRef<Menu>(null);
  const [report] = useFreshShipmentReportMutation();
  const [accountCode] = useCurrentAccountCode();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!accountCode || !data?.shipmentNum) {
                return;
              }

              const res = await report({
                accountCode,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!accountCode || !data?.shipmentNum) {
                return;
              }

              const res = await report({
                accountCode,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [accountCode, data?.shipmentNum, report],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

const ImplantPdfRenderer = ({
  data,
}: ICellRendererParams<IFreshShipmentsState>) => {
  const menu = useRef<Menu>(null);
  const [report] = useImplantFormReportMutation();
  const [accountCode] = useCurrentAccountCode();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!accountCode || !data?.shipmentNum) {
                return;
              }

              const res = await report({
                accountCode,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!accountCode || !data?.shipmentNum) {
                return;
              }

              const res = await report({
                accountCode,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [accountCode, data?.shipmentNum, report],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

export const initialFreshShipmentsColDefs: ColDefs<IFreshShipmentsState> = [
  {
    headerName: 'Shipment Form',
    field: 'shipmentForm',
    headerClass: 'header-center',
    cellRenderer: ShipmentPdfRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
    initialWidth: 128,
  },
  {
    headerName: 'Embryo Collection Form',
    field: 'embryoCollectionForm',
    headerClass: 'header-center',
    cellRenderer: ImplantPdfRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
    initialWidth: 176,
  },
  { headerName: 'Shipment #', field: 'shipmentNum', initialWidth: 123 },
  {
    headerName: 'Name',
    field: 'name',
    initialWidth: 331,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Created On',
    field: 'createdOn',
    initialWidth: 122,
    valueFormatter: dateFormatter,
    filterParams,
    filter: 'agDateColumnFilter',
  },
  {
    headerName: 'Matings',
    field: 'matings',
    initialWidth: 104,
    filter: 'agNumberColumnFilter',
  },
  { headerName: 'Shipper ID', field: 'shipperId', initialWidth: 160 },
  { headerName: 'Tracking #', field: 'trackingNum', initialWidth: 116 },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const freshShipmentsExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Fresh Shipments',
  fileName: 'Fresh Shipments',
  columnKeys: [
    'confidential',
    'shipmentNum',
    'name',
    'createdOn',
    'matings',
    'shipperId',
    'trackingNum',
  ],
};
export const freshShipmentsCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Fresh Shipments',
  fileName: 'Fresh Shipments',
};

export const freshShipmentsPdfExport = {
  fileName: 'Fresh Shipments',
  columnKeys: [
    'createdOn',
    'matings',
    'name',
    'shipmentNum',
    'shipperId',
    'trackingNum',
  ],
};

export const freshShipmentsSlice = createSlice({
  name: 'freshShipments',
  initialState: initialFreshShipmentsState,
  reducers: {
    setFreshShipments: (
      state: IFreshShipmentsState,
      action: PayloadAction<IFreshShipmentsState>,
    ) => {
      state.createdOn = action.payload.createdOn;
      state.matings = action.payload.matings;
      state.name = action.payload.name;
      state.shipmentNum = action.payload.shipmentNum;
      state.shipperId = action.payload.shipperId;
      state.trackingNum = action.payload.trackingNum;
    },
  },
});

export const { setFreshShipments } = freshShipmentsSlice.actions;

export default freshShipmentsSlice.reducer;
