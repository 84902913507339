import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { currencyFormatter, dateFormatter, filterParams } from '@transova/rtk';

import { ColDef, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import { gridOptions } from '../grid/defaults';
import {
  useCurrentAccountCode,
  useInvoiceFormReportMutation,
} from '../services';

export interface IInvoicesAllDetailState {
  itemNum?: string;
  description?: string;
  description2?: string;
  unitCost?: number;
  quantity?: number;
  totalCost?: number;
}

export interface IInvoicesAllState {
  date?: Date;
  invoiceNum?: string;
  total?: string;
  detail?: IInvoicesAllDetailState;
  confidential?: string;
}

const PdfRenderer = ({ data }: ICellRendererParams<IInvoicesAllState>) => {
  const menu = useRef<Menu>(null);
  const [accountCode] = useCurrentAccountCode();
  const [report] = useInvoiceFormReportMutation();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!data?.invoiceNum || !accountCode) {
                return;
              }

              const res = await report({
                invoiceCode: data.invoiceNum,
                accountCode,
              });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!data?.invoiceNum || !accountCode) {
                return;
              }

              const res = await report({
                invoiceCode: data.invoiceNum,
                accountCode,
              });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [report, accountCode, data?.invoiceNum],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

const cellClassRules = {
  'cell-pass': (params: any) => params.value >= 0,
  'cell-fail': (params: any) => params.value < 0,
};

export const initialInvoicesAllColDefs: ColDef<IInvoicesAllState>[] = [
  {
    headerName: 'Date',
    field: 'date',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
    initialWidth: 135,
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    headerName: 'Invoice #',
    field: 'invoiceNum',
    initialWidth: 167,
  },
  {
    headerName: 'Total',
    field: 'total',
    valueFormatter: currencyFormatter,
    cellClass: 'cell-currency',
    cellClassRules,
    filter: 'agNumberColumnFilter',
    initialWidth: 102,
  },
  {
    headerName: 'PDF',
    initialWidth: 66,
    cellRenderer: PdfRenderer,
    headerClass: 'header-center',
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
  },
  { headerName: 'Detail', field: 'detail', hide: true },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const initialInvoicesAllDetailColDefs: ColDef<IInvoicesAllDetailState>[] =
  [
    { headerName: 'Item#', field: 'itemNum' },
    { headerName: 'Description', field: 'description', minWidth: 250 },
    { headerName: 'Description 2', field: 'description2', minWidth: 250 },
    { headerName: 'Cost', field: 'unitCost' },
    { headerName: 'Quantity', field: 'quantity' },
    { headerName: 'Total', field: 'totalCost' },
  ];

export const InvoicesAllExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Invoices (All)',
  fileName: 'Invoices (All)',
  columnKeys: ['confidential', 'date', 'invoiceNum', 'total'],
};
export const InvoicesAllCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Invoices (All)',
  fileName: 'Invoices (All)',
};

export const InvoicesAllPdfExport = {
  fileName: 'Invoices (All)',
  columnKeys: ['date', 'invoiceNum', 'total'],
};
