import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface ITransfersState {
  donorId?: number;
  donorName?: string;
  calvingDate?: Date;
  fetalSex?: string;
  fourtyDay?: string;
  fourtyDayDate?: Date;
  grade?: number;
  implantDate?: string;
  method?: string;
  pregStatus?: string;
  procDate?: Date;
  recipTag?: string;
  semenType?: string;
  sire?: string;
  sixtyDay?: string;
  sixtyDayDate?: Date;
  seventyDayDate?: Date;
  stage?: number;
  straw?: number;
  earTag?: string;
  yard?: string;
  freezeDate?: Date;
  embryoBreed?: string;
  naab?: string;
  transferSiteName?: string;
  confidential?: string;
}

export const initialTransfersState: ITransfersState = {
  donorId: undefined,
  donorName: undefined,
  calvingDate: undefined,
  fetalSex: undefined,
  fourtyDay: undefined,
  fourtyDayDate: undefined,
  grade: undefined,
  implantDate: undefined,
  method: undefined,
  pregStatus: undefined,
  procDate: undefined,
  recipTag: undefined,
  semenType: undefined,
  sire: undefined,
  sixtyDay: undefined,
  sixtyDayDate: undefined,
  seventyDayDate: undefined,
  stage: undefined,
  straw: undefined,
  earTag: undefined,
  yard: undefined,
  freezeDate: undefined,
  embryoBreed: undefined,
  naab: undefined,
  transferSiteName: undefined,
};

export const initialTransfersColDefs: ColDefs<ITransfersState> = [
  {
    headerName: 'Implant Date',
    field: 'implantDate',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
    initialWidth: 131,
  },
  {
    headerName: 'Donor Id',
    field: 'donorId',
    filter: 'agNumberColumnFilter',
    initialWidth: 108,
  },
  { headerName: 'Donor', field: 'donorName', initialWidth: 150 },
  { headerName: 'Ear Tag', field: 'earTag', initialWidth: 99 },
  { headerName: 'Sire', field: 'sire', initialWidth: 160 },
  { headerName: 'Semen Type', field: 'semenType', initialWidth: 126 },
  {
    headerName: 'Stage',
    field: 'stage',
    filter: 'agNumberColumnFilter',
    initialWidth: 90,
  },
  {
    headerName: 'Grade',
    field: 'grade',
    filter: 'agNumberColumnFilter',
    initialWidth: 93,
  },
  {
    headerName: 'Straw',
    field: 'straw',
    filter: 'agNumberColumnFilter',
    initialWidth: 90,
  },
  { headerName: 'Method', field: 'method', initialWidth: 103 },
  { headerName: 'Recip #', field: 'recipTag', initialWidth: 100 },
  { headerName: 'Pregnant', field: 'pregStatus', initialWidth: 110 },
  { headerName: 'Fetal Sex', field: 'fetalSex', initialWidth: 108 },
  {
    headerName: 'Calving Date',
    field: 'calvingDate',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
    initialWidth: 129,
  },
  { headerName: '40 Day', field: 'fourtyDay', initialWidth: 98 },
  {
    headerName: '40 Day Date',
    field: 'fourtyDayDate',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
    initialWidth: 127,
  },
  { headerName: 'Sixty Day', field: 'sixtyDay', initialWidth: 110 },
  {
    headerName: 'Sixty Day Date',
    field: 'sixtyDayDate',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
    initialWidth: 139,
  },
  {
    headerName: '70 Day Date',
    field: 'seventyDayDate',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
    initialWidth: 139,
  },
  { headerName: 'Yard (State)', field: 'yard', initialWidth: 110 },
  {
    headerName: 'Embryo Freeze Date',
    field: 'freezeDate',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
    initialWidth: 169,
  },
  { headerName: 'Embryo Breed', field: 'embryoBreed', initialWidth: 84 },
  { headerName: 'NAAB Code', field: 'naab', initialWidth: 103 },
  { headerName: 'Transfer Site', field: 'transferSiteName', initialWidth: 126 },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const transfersExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Transfers',
  fileName: 'Transfers',
  columnKeys: [
    'confidential',
    'implantDate',
    'donorId',
    'donorName',
    'earTag',
    'sire',
    'semenType',
    'stage',
    'grade',
    'straw',
    'method',
    'recipTag',
    'pregStatus',
    'fetalSex',
    'calvingDate',
    'fourtyDay',
    'fourtyDayDate',
    'sixtyDay',
    'sixtyDayDate',
    'seventyDayDate',
    'yard',
    'freezeDate',
    'embryoBreed',
    'naab',
    'transferSiteName',
  ],
};
export const transfersCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Transfers',
  fileName: 'Transfers',
};

export const transfersPdfExport = {
  fileName: 'Transfers',
  columnKeys: [
    'donorId',
    'donorName',
    'calvingDate',
    'fetalSex',
    'fourtyDay',
    'fourtyDayDate',
    'grade',
    'implantDate',
    'method',
    'pregStatus',
    'recipTag',
    'semenType',
    'sire',
    'sixtyDay',
    'sixtyDayDate',
    'seventyDayDate',
    'stage',
    'straw',
    'earTag',
    'yard',
    'freezeDate',
    'embryoBreed',
    'naab',
    'transferSiteName',
  ],
};

export const transfersSlice = createSlice({
  name: 'transfers',
  initialState: initialTransfersState,
  reducers: {
    setTransfers: (
      state: ITransfersState,
      action: PayloadAction<ITransfersState>,
    ) => {
      state.donorId = action.payload.donorId;
      state.donorName = action.payload.donorName;
      state.calvingDate = action.payload.calvingDate;
      state.fetalSex = action.payload.fetalSex;
      state.fourtyDay = action.payload.fourtyDay;
      state.fourtyDayDate = action.payload.fourtyDayDate;
      state.grade = action.payload.grade;
      state.implantDate = action.payload.implantDate;
      state.method = action.payload.method;
      state.pregStatus = action.payload.pregStatus;
      state.procDate = action.payload.procDate;
      state.recipTag = action.payload.recipTag;
      state.semenType = action.payload.semenType;
      state.sire = action.payload.sire;
      state.sixtyDay = action.payload.sixtyDay;
      state.sixtyDayDate = action.payload.sixtyDayDate;
      state.seventyDayDate = action.payload.seventyDayDate;
      state.stage = action.payload.stage;
      state.straw = action.payload.straw;
      state.earTag = action.payload.earTag;
      state.yard = action.payload.yard;
      state.freezeDate = action.payload.freezeDate;
      state.embryoBreed = action.payload.embryoBreed;
      state.naab = action.payload.naab;
      state.transferSiteName = action.payload.transferSiteName;
    },
  },
});

export const { setTransfers } = transfersSlice.actions;

export default transfersSlice.reducer;
