import { CustomViews, Spinner } from '@transova/ui';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  ColDef,
  ExcelStyle,
  GridOptions,
  SideBarDef,
} from '../components/ag-grid';
import { setQuickFilter } from '../reducers/quickFilterSlice';

export const defaultColDef: ColDef<any> = {
  sortable: true,
  pivot: false,
  filter: true,
  wrapText: false,
  resizable: true,
  autoHeight: false,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  enableValue: false,
  enableRowGroup: true,
  enablePivot: false,
};

export const defaultDetailColDef: ColDef<any> = {
  flex: 1,
  sortable: true,
  pivot: false,
  wrapText: false,
  resizable: true,
  autoHeight: false,
  wrapHeaderText: false,
  autoHeaderHeight: true,
  suppressAutoSize: false,
};

export const cardViewColDef: ColDef<any> = {
  sortable: false,
  pivot: false,
  filter: true,
  wrapText: false,
  resizable: true,
  autoHeight: false,
  enableValue: false,
  enablePivot: false,
};

export const icons = {
  'custom-views': '<span class="ag-icon ag-icon-eye"></span>',
  settings: '<span class="ag-icon ag-icon-settings"></span>',
};

export const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
    {
      id: 'customViews',
      labelDefault: 'Custom Views',
      labelKey: 'customViews',
      iconKey: 'custom-views',
      toolPanel: CustomViews,
      toolPanelParams: {
        setQuickFilter,
        useAppDispatch,
        useAppSelector,
      },
    },
  ],
};

export const gridOptions: GridOptions = {
  defaultColDef,
  icons,
  sideBar,
  overlayNoRowsTemplate: '<span class="grid-no-rows">No data to display</span>',
  loadingOverlayComponent: Spinner,
  defaultCsvExportParams: {
    prependContent: [[], [{ data: { value: 'CONFIDENTIAL' } }], []],
  },
  defaultExcelExportParams: {},
};

export const cardViewOptions: GridOptions = {
  defaultColDef: cardViewColDef,
  icons,
  overlayNoRowsTemplate: '<span class="grid-no-rows">No data to display</span>',
  sideBar: false,
  loadingOverlayComponent: Spinner,
};

export const excelStyling: ExcelStyle = {
  id: 'header',
  font: {
    color: '#FFFFFF',
  },
  interior: {
    color: '#7A7A7A',
    pattern: 'Solid',
    patternColor: undefined,
  },
};
export const excelStyles = [excelStyling];
