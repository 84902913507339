import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';

interface IPcStatus {
  donor: string;
  pregPend: number;
  pregOpen: number;
  pregPreg: number;
  bulls: number;
  heifers: number;
}

interface IProps {
  data: IPcStatus;
}

export default (props: IProps) => {
  const status: IPcStatus = props.data;

  const op = useRef<OverlayPanel>(null);

  return (
    <>
      <span>
        <Button
          type="button"
          icon="pi pi-search"
          label={'PC Status'}
          onClick={(e) => op.current?.toggle(e)}
          aria-haspopup
          aria-controls="overlay_panel"
          className="overlay-panel__btn overlay-panel__btn-ivfprodsum"
        />
      </span>
      <OverlayPanel ref={op} showCloseIcon className="overlay-panel">
        <header className="overlay-panel__header">
          <h4>{status.donor}</h4>
        </header>
        <div className="overlay-panel__body">
          <label className="overlay-panel__label overlay-panel__label-ivfprodsum-donor">
            Donor:
          </label>
          <span className="overlay-panel__value overlay-panel__value-ivfprodsum-donor">
            {status.donor}
          </span>
          <label className="overlay-panel__label overlay-panel__label-ivfprodsum-pregPend">
            Pending:
          </label>
          <span className="overlay-panel__value overlay-panel__value-ivfprodsum-pregPend">
            {status.pregPend}
          </span>
          <label className="overlay-panel__label overlay-panel__label-ivfprodsum-pregOpen">
            Open:
          </label>
          <span className="overlay-panel__value overlay-panel__value-ivfprodsum-pregOpen">
            {status.pregOpen}
          </span>
          <label className="overlay-panel__label overlay-panel__label-ivfprodsum-pregPreg">
            Pregnant:
          </label>
          <span className="overlay-panel__value overlay-panel__value-ivfprodsum-pregPreg">
            {status.pregPreg}
          </span>
          <label className="overlay-panel__label overlay-panel__label-ivfprodsum-bulls">
            Bulls:
          </label>
          <span className="overlay-panel__value overlay-panel__value-ivfprodsum-bulls">
            {status.bulls}
          </span>
          <label className="overlay-panel__label overlay-panel__label-ivfprodsum-heifers">
            Heifers:
          </label>
          <span className="overlay-panel__value overlay-panel__value-ivfprodsum-heifers">
            {status.heifers}
          </span>
        </div>
      </OverlayPanel>
    </>
  );
};
