import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CsvExportParams, ExcelExportParams } from 'ag-grid-community';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface IDonorsState {
  donorName?: string;
  earTag?: string;
  regNum?: string;
  pedigreeName?: string;
  birthdate?: Date;
  yard?: string;
  breed?: string;
  implantStatus?: string;
  id?: string;
  isActive?: boolean;
  confidential?: string;
}

export const initialDonorsState: IDonorsState = {
  donorName: '',
  earTag: '',
  regNum: '',
  pedigreeName: '',
  birthdate: undefined,
  yard: '',
  breed: '',
  implantStatus: '',
  id: '',
  isActive: false,
};

export const initialDonorsColDefs: ColDefs<IDonorsState> = [
  {
    headerName: 'Donor',
    field: 'donorName',
    initialWidth: 194,
    cellRenderer: 'agGroupCellRenderer',
  },
  { headerName: 'Ear Tag', field: 'earTag', initialWidth: 109 },
  { headerName: 'Breed', field: 'breed', initialWidth: 92 },
  { headerName: 'Reg #', field: 'regNum', initialWidth: 141 },
  { headerName: 'Pedigree', field: 'pedigreeName', initialWidth: 251 },
  {
    headerName: 'Birth Date',
    field: 'birthdate',
    initialWidth: 115,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
  },
  { headerName: 'Yard', field: 'yard', initialWidth: 84 },
  { headerName: 'Pregnant', field: 'implantStatus', initialWidth: 110 },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const cardViewDonorsColDefs: ColDefs<any> = [
  {
    headerName: 'Donor',
    field: 'donorName',
    flex: 1,
    valueGetter: (params) =>
      `${params.data.donorName} / ${params.data.birthdate.substring(0, 10)}`,
  },
];

export const donorsExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Donors',
  fileName: 'Donors',
  columnKeys: [
    'confidential',
    'donorName',
    'earTag',
    'breed',
    'regNum',
    'pedigreeName',
    'birthdate',
    'yard',
    'implantStatus',
  ],
} as ExcelExportParams;
export const donorsCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Donors',
  fileName: 'Donors',
} as CsvExportParams;

export const donorsPdfExport = {
  fileName: 'Donors',
  columnKeys: [
    'donorName',
    'earTag',
    'breed',
    'regNum',
    'pedigreeName',
    'birthdate',
    'yard',
    'implantStatus',
  ],
};

export const donorsSlice = createSlice({
  name: 'donors',
  initialState: initialDonorsState,
  reducers: {
    setDonors: (state: IDonorsState, action: PayloadAction<IDonorsState>) => {
      state.donorName = action.payload.donorName;
      state.earTag = action.payload.earTag;
      state.breed = action.payload.breed;
      state.regNum = action.payload.regNum;
      state.pedigreeName = action.payload.pedigreeName;
      state.birthdate = action.payload.birthdate;
      state.yard = action.payload.yard;
      state.implantStatus = action.payload.implantStatus;
    },
  },
});

export const { setDonors } = donorsSlice.actions;

export default donorsSlice.reducer;
