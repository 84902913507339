import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDef, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import { gridOptions } from '../grid/defaults';
import {
  useCurrentAccountCode,
  useEmailPdfMutation,
  usePdfReportMutation,
} from '../services';

export interface IStatementsState {
  clientCode?: string;
  statementDate?: Date;
  filename?: string;
  confidential?: string;
}

const PdfRenderer = ({ data }: ICellRendererParams<IStatementsState>) => {
  const menu = useRef<Menu>(null);
  const [emailPdf] = useEmailPdfMutation();
  const [accountCode] = useCurrentAccountCode();
  const [report] = usePdfReportMutation();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!data?.filename) {
                return;
              }

              const res = await report({ path: data.filename });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!data?.filename) {
                return;
              }

              const res = await report({ path: data.filename });
              openReport(res, 'attachment');
            },
          },
          {
            label: 'Email',
            icon: 'pi pi-envelope',
            async command() {
              if (!data?.filename || !accountCode) {
                return;
              }
              await emailPdf({ path: data.filename, accountCode });
              //toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
            },
          },
        ],
      },
    ],
    [data, emailPdf, accountCode, report],
  );

  if (!data) {
    return null;
  }
  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

export const initialStatementsColDefs: ColDef<IStatementsState>[] = [
  {
    headerName: 'Account #',
    field: 'clientCode',
    filter: 'agTextColumnFilter',
    initialWidth: 115,
  },
  {
    headerName: 'Statement Date',
    field: 'statementDate',
    filter: 'agDateColumnFilter',
    initialWidth: 145,
    valueFormatter: dateFormatter,
    filterParams,
  },
  {
    headerName: 'PDF',
    field: 'filename',
    filter: 'agTextColumnFilter',
    initialWidth: 66,
    headerClass: 'header-center',
    cellRenderer: PdfRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
  },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const statementsExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Invoices (By Donor)',
  fileName: 'Invoices (By Donor)',
  columnKeys: ['confidential', 'clientCode', 'statementDate', 'filename'],
};
export const statementsCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Invoices (By Donor)',
  fileName: 'Invoices (By Donor)',
};

export const statementsPdfExport = {
  fileName: 'Invoices (By Donor)',
  columnKeys: ['clientCode', 'statementDate', 'filename'],
};
