import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICurrentRouteState {
  path?: string;
}

export const initialCurrentRouteState: ICurrentRouteState = {
  path: undefined,
};

export const currentRouteSlice = createSlice({
  name: 'currentRoute',
  initialState: initialCurrentRouteState,
  reducers: {
    setCurrentRoute: (
      state: ICurrentRouteState,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      path: action.payload,
    }),
  },
});

export const { setCurrentRoute } = currentRouteSlice.actions;

export default currentRouteSlice.reducer;
