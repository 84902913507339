import { ColDef } from 'ag-grid-community';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  getCircularReplacer,
  ICustomViewProps,
  resetCustomViews,
  setCurrentViewId,
  useAddViewMutation,
  useDefaultViewMutation,
  useGetViewByIdQuery,
  useGetViewNamesQuery,
  useMeQuery,
  useRemoveViewMutation,
  useUpdateViewMutation,
} from '@transova/rtk';

import './custom-views.scss';

export const CustomViews = (props: ICustomViewProps) => {
  const me = useMeQuery();
  const username = me.data?.username;

  const dispatch = props.useAppDispatch();

  const toast = useRef<Toast>(null);
  const addViewRef = useRef<any>(null);

  const [displayAddView, setDisplayAddView] = useState<boolean>(false);
  const [addViewName, setAddViewName] = useState<string>('');
  const [defaultState, setDefaultState] = useState<any>();

  useMemo(() => {
    setDefaultState(props.columnApi.getAllGridColumns());
  }, []);

  const dialogFuncMap = useMemo(
    () => ({
      displayAddView: setDisplayAddView,
    }),
    [],
  );

  const onClick = useCallback(
    (name: keyof typeof dialogFuncMap) => {
      dialogFuncMap[`${name}`](true);
    },
    [dialogFuncMap],
  );

  const onHide = useCallback(
    (name: keyof typeof dialogFuncMap) => {
      dialogFuncMap[`${name}`](false);
    },
    [dialogFuncMap],
  );

  const reject = useCallback(() => {
    toast.current?.show({
      severity: 'warn',
      summary: 'Rejected',
      detail: 'You have rejected',
      life: 3000,
    });
  }, []);

  const currentView = useSelector((state: any) => state.currentView);
  const [queryParams] = useSearchParams();
  const location = useLocation();
  const parts = useMemo(
    () => location.pathname.split('/'),
    [location.pathname],
  );
  const primary: string = parts[1]?.toLowerCase() ?? '';
  const secondary: string = parts[2]?.toLowerCase() ?? '';
  const ranch: string = queryParams.get('ranch') ?? '';
  const tabIndex: string = queryParams.get('tabIndex') ?? '0';
  const routePath = `primary=${primary}&secondary=${secondary}&ranch=${ranch}&tabIndex=${tabIndex}`;

  const { data: viewList } = useGetViewNamesQuery({
    username: username ?? '',
    usage: routePath,
  });

  const { data: selectedView, refetch: refetchGetViewById } =
    useGetViewByIdQuery(currentView.currentViewId, {
      skip: !currentView.currentViewId,
    });

  useEffect(() => {
    props.columnApi.resetColumnState();
    props.columnApi.applyColumnState({
      defaultState: { sort: null, rowGroup: false },
      applyOrder: true,
    });
    props.api.setFilterModel({});
    setDefaultState(props.api.getColumnDefs());

    return () => {
      dispatch(resetCustomViews());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routePath]);

  useEffect(() => {
    if (selectedView) {
      resetColumns();
      const json = JSON.parse(selectedView.view);
      const cd: any[] = json.columnDefs.map((x: any) =>
        props.api
          .getColumnDefs()!
          .find((y) => 'colId' in y && y.colId === x.colId),
      );
      const columnDefs: any[] = json.columnDefs.map(
        (x: ColDef, index: number) => {
          return { ...cd[index], ...x };
        },
      );

      props.columnApi.resetColumnGroupState();
      props.columnApi.setRowGroupColumns(json.rowGroupColumns);
      props.api.setColumnDefs(columnDefs);
      props.api.setFilterModel(json.filterModel);
      props.api.setQuickFilter(json.quickFilter);
      dispatch(props.setQuickFilter(json.quickFilter || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView]);

  const [addView] = useAddViewMutation();
  const [updateView] = useUpdateViewMutation();
  const [removeView] = useRemoveViewMutation();
  const [defaultView] = useDefaultViewMutation();

  const AddFooter = useCallback(
    (name: keyof typeof dialogFuncMap) => {
      const model: any = props.api.getModel();
      const quickFilter = model.filterManager.quickFilter;

      const add = (name: string) => {
        const gridColumns = props.columnApi.getAllGridColumns();
        const columnDefs = props.api
          .getColumnDefs()
          ?.map((col: ColDef, index: number) => {
            const width = gridColumns[index].getActualWidth();

            return {
              colId: col.colId,
              hide: col.hide,
              pinned: col.pinned,
              sort: col.sort,
              sortIndex: col.sortIndex,
              width,
            };
          });

        addView({
          name,
          username: username ?? '',
          usage: `app=${import.meta.env.VITE_USAGE_APP}&${routePath}`,
          view: JSON.stringify(
            {
              columnDefs,
              filterModel: props.api.getFilterModel(),
              quickFilter,
              rowGroupColumns: props.columnApi.getRowGroupColumns(),
            },
            getCircularReplacer(),
          ),
        })
          .unwrap()
          .then((payload: any) => {
            toast.current?.show({
              severity: 'success',
              summary: 'Save',
              detail: 'The new view has been saved.',
              life: 3000,
            });
            onHide('displayAddView');
            dispatch(setCurrentViewId(payload.id));
            setAddViewName('');
          })
          .catch((error: any) => {
            console.log('error ::', error);
          });
      };

      const cancel = () => {
        setAddViewName('');
        onHide(name);
      };

      return (
        <div>
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={cancel}
            className="p-button-text"
          />
          <Button
            label="Add"
            icon="pi pi-check"
            onClick={() => add(addViewName)}
            autoFocus
          />
        </div>
      );
    },
    [
      addView,
      addViewName,
      routePath,
      dispatch,
      onHide,
      props.api,
      props.columnApi,
      username,
    ],
  );

  const showUpdateViewDialog = () => {
    const model: any = props.api.getModel();
    const quickFilter = model.filterManager.quickFilter;

    confirmDialog({
      message: 'Are you sure you wish to update the current view?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const gridColumns = props.columnApi.getAllGridColumns();
        const columnDefs = props.api
          .getColumnDefs()
          ?.map((col: any, index: number) => {
            const width = gridColumns[index].getActualWidth();

            return {
              ...col,
              initialWidth: width,
            };
          });

        updateView({
          id: currentView.currentViewId,
          view: JSON.stringify(
            {
              columnDefs,
              filterModel: props.api.getFilterModel(),
              quickFilter,
              rowGroupColumns: props.columnApi.getRowGroupColumns(),
            },
            getCircularReplacer(),
          ),
        })
          .then(() => {
            refetchGetViewById();
            toast.current?.show({
              severity: 'success',
              summary: 'Updated',
              detail: 'The current view has been updated.',
              life: 3000,
            });
            onHide('displayAddView');
          })
          .catch((error: any) => {
            console.log('error ::', error);
            reject();
          });
      },
    });
  };

  const showDeleteViewDialog = (id: string) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        removeView(id)
          .then(() => {
            toast.current?.show({
              severity: 'success',
              summary: 'Save',
              detail: 'The new view has been saved.',
              life: 3000,
            });
            onHide('displayAddView');
          })
          .catch((error: any) => {
            console.log('error ::', error);
            reject();
          });
      },
    });
  };

  const focusOnFirstInput = () => {
    addViewRef.current.getContent().querySelector('input').focus();
  };

  const handleSelectView = (id: string) => {
    dispatch(setCurrentViewId(id));
  };

  const resetColumns = () => {
    props.columnApi.applyColumnState({
      defaultState: { sort: null, rowGroup: false },
      applyOrder: true,
    });
    props.api.setFilterModel({});
    props.api.setQuickFilter('');
    props.api.setColumnDefs(defaultState);
    props.columnApi.resetColumnState();
  };

  const handleClearAllFilters = () => {
    resetColumns();

    dispatch(props.setQuickFilter(''));
    dispatch(resetCustomViews());
  };

  const handleDefaultView = (viewId: string) => {
    defaultView({
      username: username ?? '',
      usage: `${import.meta.env.VITE_USAGE_APP}|${routePath}`,
      viewId,
    });
  };

  return (
    <div className="custom-views">
      <Toast ref={toast} />

      <div className="custom-views__list">
        <div className="[ custom-views__section-label ] [ section bottom-separater ]">
          My Views
        </div>
        <div className="custom-views__my-views">
          {!viewList?.length && (
            <div className="custom-views__no-list">No Views</div>
          )}
          {viewList?.map((view: any) => (
            <div className="custom-views__view" key={view.id}>
              <div
                className="custom-views__view-default"
                data-state={view.isDefault === true ? 'active' : ''}
                onClick={() => handleDefaultView(view.id)}
              >
                Make Default
              </div>
              <div
                className="custom-views__view-label"
                data-state={
                  view.id === currentView.currentViewId ? 'active' : ''
                }
                onClick={() => handleSelectView(view.id)}
              >
                <i className="pi pi-eye"></i>
                <span>{view.name}</span>
              </div>
              <div
                className="custom-views__view-delete"
                onClick={() => showDeleteViewDialog(view.id)}
              >
                Delete
              </div>
            </div>
          ))}
        </div>
        <div className="custom-views__actions">
          <div
            className="custom-views__action"
            onClick={() => onClick('displayAddView')}
          >
            Save Filters as New View
          </div>
          <div
            className="custom-views__action"
            data-state={!currentView.currentViewId ? 'disabled' : ''}
            onClick={showUpdateViewDialog}
          >
            Save Filters to Current View
          </div>
        </div>
        <div className="custom-views__actions">
          <div
            className="[ custom-views__action ] [ section top-separater ]"
            onClick={handleClearAllFilters}
          >
            Clear All Filters
          </div>
        </div>
      </div>

      <Dialog
        ref={addViewRef}
        header="New View"
        visible={displayAddView}
        footer={AddFooter('displayAddView')}
        onHide={() => onHide('displayAddView')}
        onShow={() => focusOnFirstInput()}
      >
        <div className="view-name-dialog__fields">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-eye"></i>
            </span>
            <InputText
              placeholder="Name"
              autoFocus={true}
              value={addViewName}
              onChange={({ target: { value } }) => setAddViewName(value)}
            />
          </div>
        </div>
      </Dialog>

      <ConfirmDialog />
    </div>
  );
};
