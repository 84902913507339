import { dateFormatter, filterParams, percentFormatter } from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface ISemenInventoryState {
  sire?: string;
  breed?: string;
  regNum?: string;
  cane?: string;
  colCode?: string;
  conc?: string;
  quantity?: number;
  sex?: string;
  quantityReceived?: number;
  dateReceived?: Date;
  location?: string;
  site?: string;
  soldTo?: string;
  exportCountry?: string;
  export?: string;
  comment?: string;
  secondaryOwner?: string;
  sireEdr?: number;
  confidential?: string;
}

export const initialSemenInventoryColDefs: ColDefs<ISemenInventoryState> = [
  { headerName: 'Sire', field: 'sire', initialWidth: 148 },
  { headerName: 'Breed', field: 'breed', initialWidth: 92 },
  { headerName: 'Reg #', field: 'regNum', initialWidth: 148 },
  { headerName: 'NAAB/Cane', field: 'cane', initialWidth: 113 },
  { headerName: 'Col Code', field: 'colCode', initialWidth: 110 },
  {
    headerName: 'Conc',
    field: 'conc',
    initialWidth: 87,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
  },
  {
    headerName: 'Qty',
    field: 'quantity',
    initialWidth: 80,
    filter: 'agNumberColumnFilter',
  },
  { headerName: 'Sex', field: 'sex', initialWidth: 79 },
  {
    headerName: 'Qty Recv',
    field: 'quantityReceived',
    initialWidth: 108,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Date Recv',
    field: 'dateReceived',
    initialWidth: 114,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
  },
  { headerName: 'location', field: 'location', initialWidth: 108 },
  { headerName: 'Site', field: 'site', initialWidth: 241 },
  { headerName: 'Secondary Owner', field: 'secondaryOwner', initialWidth: 130 },
  { headerName: 'Sold To', field: 'soldTo', initialWidth: 100 },
  { headerName: 'Export Country', field: 'exportCountry', initialWidth: 100 },
  { headerName: 'Export', field: 'export', initialWidth: 100 },
  { headerName: 'Comment', field: 'comment', initialWidth: 200 },
  {
    headerName: 'Sire EDR',
    field: 'sireEdr',
    initialWidth: 100,
    valueFormatter: percentFormatter,
  },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const initialSemenInventoryWithoutExportColDefs: ColDefs<ISemenInventoryState> =
  [
    { headerName: 'Sire', field: 'sire', initialWidth: 148 },
    { headerName: 'Breed', field: 'breed', initialWidth: 92 },
    { headerName: 'Reg #', field: 'regNum', initialWidth: 148 },
    { headerName: 'NAAB/Cane', field: 'cane', initialWidth: 113 },
    { headerName: 'Col Code', field: 'colCode', initialWidth: 110 },
    {
      headerName: 'Conc',
      field: 'conc',
      initialWidth: 87,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    {
      headerName: 'Qty',
      field: 'quantity',
      initialWidth: 80,
      filter: 'agNumberColumnFilter',
    },
    { headerName: 'Sex', field: 'sex', initialWidth: 79 },
    {
      headerName: 'Qty Recv',
      field: 'quantityReceived',
      initialWidth: 108,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Date Recv',
      field: 'dateReceived',
      initialWidth: 114,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    { headerName: 'Location', field: 'location', initialWidth: 108 },
    { headerName: 'Site', field: 'site', initialWidth: 241 },
    {
      headerName: 'Secondary Owner',
      field: 'secondaryOwner',
      initialWidth: 130,
    },
    { headerName: 'Sold To', field: 'soldTo', initialWidth: 100 },
    { headerName: 'Comment', field: 'comment', initialWidth: 200 },
    {
      headerName: 'Sire EDR',
      field: 'sireEdr',
      initialWidth: 100,
      valueFormatter: percentFormatter,
    },
  ];

export const semenInventoryExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Semen Inventory',
  fileName: 'Semen Inventory',
  columnKeys: [
    'confidential',
    'sire',
    'breed',
    'regNum',
    'cane',
    'colCode',
    'conc',
    'quantity',
    'sex',
    'quantityReceived',
    'dateReceived',
    'location',
    'site',
    'secondaryOwner',
    'soldTo',
    'exportCountry',
    'export',
    'comment',
    'sireEdr',
  ],
};
export const semenInventoryCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Semen Inventory',
  fileName: 'Semen Inventory',
};

export const semenInventoryPdfExport = {
  fileName: 'Semen Inventory',
  columnKeys: [
    'sire',
    'breed',
    'regNum',
    'cane',
    'colCode',
    'conc',
    'quantity',
    'sex',
    'quantityReceived',
    'dateReceived',
    'location',
    'site',
    'secondaryOwner',
    'soldTo',
    'comment',
    'sireEdr',
  ],
};
