import { CsvExportParams, ExcelExportParams } from 'ag-grid-community';
import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import { gridOptions } from '../grid/defaults';
import {
  useAnimalShipmentReportMutation,
  useCurrentAccount,
  useFrozenShipmentReportMutation,
} from '../services';

export interface IAnimalShipmentsState {
  containsRecips?: boolean;
  containsDonors?: boolean;
  createdOn?: Date;
  ranchId?: string;
  shipCity?: string;
  shipDate?: Date;
  shipmentNum?: string;
  shipName?: string;
  shipState?: string;
  confidential?: string;
  shipmentForm?: string;
  calfHistory?: string;
}

const CheckmarkIfTrueRenderer = (props: ICellRendererParams) => {
  return (
    <span className="custom-cell__checkmark">{props.value ? '✓' : ''}</span>
  );
};

const ShippingPdfRenderer = ({
  data,
}: ICellRendererParams<IAnimalShipmentsState>) => {
  const menu = useRef<Menu>(null);
  const [account] = useCurrentAccount();
  const [report] = useFrozenShipmentReportMutation();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!data?.shipmentNum || !account.data?.code) {
                return;
              }

              const res = await report({
                accountCode: account.data.code,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!data?.shipmentNum || !account.data?.code) {
                return;
              }

              const res = await report({
                accountCode: account.data.code,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [account?.data?.code, data?.shipmentNum, report],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

const CalfHistoryPdfRenderer = ({
  data,
}: ICellRendererParams<IAnimalShipmentsState>) => {
  const menu = useRef<Menu>(null);
  const [account] = useCurrentAccount();
  const [report] = useAnimalShipmentReportMutation();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!data?.shipmentNum || !account.data?.id) {
                return;
              }

              const res = await report({
                accountId: account.data.id,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!data?.shipmentNum || !account.data?.id) {
                return;
              }

              const res = await report({
                accountId: account.data.id,
                shipmentCode: data.shipmentNum,
              });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [account?.data?.id, data?.shipmentNum, report],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

export const initialAnimalShipmentsColDefs: ColDefs<IAnimalShipmentsState> = [
  {
    headerName: 'Shipping Form',
    field: 'shipmentForm',
    headerClass: 'header-center',
    cellRenderer: ShippingPdfRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
    initialWidth: 110,
  },
  {
    headerName: 'Calf History',
    field: 'calfHistory',
    headerClass: 'header-center',
    cellRenderer: CalfHistoryPdfRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
    initialWidth: 108,
  },
  { headerName: 'Shipment #', field: 'shipmentNum', initialWidth: 123 },
  {
    headerName: 'Created On',
    field: 'createdOn',
    valueFormatter: dateFormatter,
    filterParams,
    filter: 'agDateColumnFilter',
    initialWidth: 122,
  },
  {
    headerName: 'Ship Date',
    field: 'shipDate',
    valueFormatter: dateFormatter,
    filterParams,
    filter: 'agDateColumnFilter',
    initialWidth: 113,
  },
  { headerName: 'Ship Name', field: 'shipName', initialWidth: 199 },
  { headerName: 'Ship City', field: 'shipCity', initialWidth: 135 },
  { headerName: 'Ship State', field: 'shipState', initialWidth: 115 },
  {
    headerName: 'Contains Recips',
    field: 'containsRecips',
    cellRenderer: CheckmarkIfTrueRenderer,
    initialWidth: 146,
    cellStyle: { display: 'grid', placeContent: 'center' },
  },
  {
    headerName: 'Contains Donors',
    field: 'containsDonors',
    initialWidth: 150,
    cellRenderer: CheckmarkIfTrueRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
  },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const animalShipmentsExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Animal Shipments',
  fileName: 'Animal Shipments',
  columnKeys: [
    'confidential',
    'shipmentNum',
    'createdOn',
    'shipDate',
    'shipName',
    'shipCity',
    'shipState',
    'containsRecips',
    'containsDonors',
  ],
} as ExcelExportParams;
export const animalShipmentsCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Animal Shipments',
  fileName: 'Animal Shipments',
} as CsvExportParams;

export const animalShipmentsPdfExport = {
  fileName: 'Animal Shipments',
  columnKeys: [
    'shipmentNum',
    'createdOn',
    'shipDate',
    'shipName',
    'shipCity',
    'shipState',
    'containsRecips',
    'containsDonors',
  ],
};
