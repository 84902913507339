import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICurrentBalance {
  currentBalance?: number;
  current?: number;
  days30To60?: number;
  days60To90?: number;
  days90To180?: number;
  over180Days?: number;
}

export interface IPaymentHistory {
  date: string;
  lastPayment: number;
}

export interface IYtdSales {
  ytdSales: number;
}

export interface IInvoiceFinancialsState {
  currentBalance?: ICurrentBalance;
  togatBalance?: ICurrentBalance;
  paymentHistory?: IPaymentHistory[];
  ytdSales?: IYtdSales[];
}

export const initialInvoiceFinancialsState: IInvoiceFinancialsState = {
  currentBalance: undefined,
  paymentHistory: undefined,
  ytdSales: undefined,
};

export const invoiceFinancialsSlice = createSlice({
  name: 'invoiceFinancials',
  initialState: initialInvoiceFinancialsState,
  reducers: {
    setInvoiceFinancials: (
      state: IInvoiceFinancialsState,
      action: PayloadAction<IInvoiceFinancialsState>,
    ) => {
      state.currentBalance = action.payload.currentBalance;
      state.paymentHistory = action.payload.paymentHistory;
      state.ytdSales = action.payload.ytdSales;
    },
  },
});

export const { setInvoiceFinancials } = invoiceFinancialsSlice.actions;

export default invoiceFinancialsSlice.reducer;
