import { Middleware } from '@reduxjs/toolkit';

import { addRequest, removeRequest } from '../features/spinner/spinnerSlice';

export const spinner: Middleware = (api) => (next) => (action) => {
  if (!action.meta) return next(action);

  const ignore: boolean = api
    .getState()
    .spinner.ignoreRequestsFrom.some(
      (e: string) => action.meta.arg?.endpointName === e,
    );

  const { requestId, requestStatus } = action.meta;
  const exists = api.getState().spinner.pending.includes(requestId);

  if (!ignore && !exists && requestStatus === 'pending') {
    api.dispatch(addRequest(requestId));
  } else if (
    !ignore &&
    exists &&
    (requestStatus === 'fulfilled' || requestStatus === 'rejected')
  ) {
    api.dispatch(removeRequest(requestId));
  }

  return next(action);
};
