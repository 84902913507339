import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import { gridOptions } from '../grid/defaults';
import { useCalfHistoryReportMutation } from '../services';

export interface ICalvesAllState {
  tagNum?: string;
  owner?: string;
  birthDate?: Date;
  sex?: string;
  registry?: string;
  ownerId?: string;
  secondTissue?: string;
  donor?: string;
  sire?: string;
  recip?: string;
  genomicDecision?: number;
  calfStatus?: string;
  pen?: string;
  breed?: string;
  shipDeathDate?: Date;
  yard?: string;
  method?: string;
  tattoo?: string;
  embryo?: string;
  shipment?: string;
  ddEntryDate?: Date;
  weight?: number;
  calfId?: string;
  confidential?: string;
}
export interface ICalvesCurrentState {
  tagNum?: string;
  owner?: string;
  birthDate?: Date;
  sex?: string;
  registry?: string;
  ownerId?: string;
  secondTissue?: string;
  donor?: string;
  sire?: string;
  recip?: string;
  genomicDecision?: number;
  calfStatus?: string;
  pen?: string;
  breed?: string;
  shipDeathDate?: Date;
  yard?: string;
  method?: string;
  tattoo?: string;
  embryo?: string;
  shipment?: string;
  ddEntryDate?: Date;
  weight?: number;
  calfId?: string;
  confidential?: string;
}

export const initialCalvesAllState: ICalvesAllState = {
  tagNum: undefined,
  owner: undefined,
  birthDate: undefined,
  sex: undefined,
  registry: undefined,
  ownerId: undefined,
  secondTissue: undefined,
  donor: undefined,
  sire: undefined,
  recip: undefined,
  genomicDecision: undefined,
  calfStatus: undefined,
  pen: undefined,
  breed: undefined,
  shipDeathDate: undefined,
  yard: undefined,
  method: undefined,
  tattoo: undefined,
  embryo: undefined,
  shipment: undefined,
  ddEntryDate: undefined,
  weight: undefined,
  calfId: undefined,
};

const PdfRenderer = ({ data }: ICellRendererParams<ICalvesAllState>) => {
  const menu = useRef<Menu>(null);
  const [report] = useCalfHistoryReportMutation();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!data?.calfId) {
                return;
              }

              const res = await report({ calfId: data.calfId });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!data?.calfId) {
                return;
              }

              const res = await report({ calfId: data.calfId });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [data?.calfId, report],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

export const initialCalvesAllColDefs: ColDefs<ICalvesAllState> = [
  {
    headerName: 'Calf History',
    field: 'shipment',
    headerClass: 'header-center',
    initialWidth: 108,
    cellRenderer: PdfRenderer,
    cellStyle: { display: 'grid', placeContent: 'center' },
    suppressMenu: true,
    sortable: false,
  },
  { headerName: 'Tag #', field: 'tagNum', initialWidth: 89 },
  {
    headerName: 'Birth Date',
    field: 'birthDate',
    initialWidth: 115,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
  },
  { headerName: 'Sex', field: 'sex', initialWidth: 82 },
  { headerName: 'Registry', field: 'registry', initialWidth: 141 },
  { headerName: 'Owner Id', field: 'ownerId', initialWidth: 110 },
  { headerName: '2nd Tissue', field: 'secondTissue', initialWidth: 118 },
  { headerName: 'Donor', field: 'donor', initialWidth: 130 },
  { headerName: 'Sire', field: 'sire', initialWidth: 118 },
  { headerName: 'Recip', field: 'recip', initialWidth: 90 },
  {
    headerName: 'Genomic Decision',
    field: 'genomicDecision',
    initialWidth: 158,
    filter: 'agNumberColumnFilter',
  },
  { headerName: 'Calf Status', field: 'calfStatus', initialWidth: 118 },
  { headerName: 'Pen', field: 'pen', initialWidth: 80 },
  { headerName: 'Breed', field: 'breed', initialWidth: 92 },
  {
    headerName: 'Ship/Death Date',
    field: 'shipDeathDate',
    initialWidth: 150,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
  },
  { headerName: 'Yard', field: 'yard', initialWidth: 211 },
  { headerName: 'Method', field: 'method', initialWidth: 103 },
  { headerName: 'Tattoo', field: 'tattoo', initialWidth: 95 },
  { headerName: 'Embryo', field: 'embryo', initialWidth: 102 },
  { headerName: 'Shipment', field: 'shipment', initialWidth: 112 },
  {
    headerName: 'DD Entry Date',
    field: 'ddEntryDate',
    initialWidth: 138,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    filterParams,
  },
  {
    headerName: 'Weight',
    field: 'weight',
    initialWidth: 99,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const calvesAllExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Calves All',
  fileName: 'Calves All',
};
export const calvesAllCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Calves All',
  fileName: 'Calves All',
};

export const calvesAllPdfExport = {
  fileName: 'Calves All',
  columnKeys: [
    'confidential',
    'tagNum',
    'owner',
    'birthDate',
    'sex',
    'registry',
    'ownerId',
    'secondTissue',
    'donor',
    'sire',
    'recip',
    'genomicDecision',
    'calfStatus',
    'pen',
    'breed',
    'shipDeathDate',
    'yard',
    'method',
    'tattoo',
    'embryo',
    'shipment',
    'ddEntryDate',
    'weight',
    'calfId',
  ],
};
export const calvesCurrentExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Calves Current',
  fileName: 'Calves Current',
};
export const calvesCurrentCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Calves Current',
  fileName: 'Calves Current',
};

export const calvesCurrentPdfExport = {
  fileName: 'Calves Current',
  columnKeys: [
    'confidential',
    'tagNum',
    'owner',
    'birthDate',
    'sex',
    'registry',
    'ownerId',
    'secondTissue',
    'donor',
    'sire',
    'recip',
    'genomicDecision',
    'calfStatus',
    'pen',
    'breed',
    'shipDeathDate',
    'yard',
    'method',
    'tattoo',
    'embryo',
    'shipment',
    'ddEntryDate',
    'weight',
    'calfId',
  ],
};
export const calvesAllSlice = createSlice({
  name: 'calvesAll',
  initialState: initialCalvesAllState,
  reducers: {
    setCalvesAll: (
      state: ICalvesAllState,
      action: PayloadAction<ICalvesAllState>,
    ) => {
      state.tagNum = action.payload.tagNum;
      state.owner = action.payload.owner;
      state.birthDate = action.payload.birthDate;
      state.sex = action.payload.sex;
      state.registry = action.payload.registry;
      state.ownerId = action.payload.ownerId;
      state.secondTissue = action.payload.secondTissue;
      state.donor = action.payload.donor;
      state.sire = action.payload.sire;
      state.recip = action.payload.recip;
      state.genomicDecision = action.payload.genomicDecision;
      state.calfStatus = action.payload.calfStatus;
      state.pen = action.payload.pen;
      state.breed = action.payload.breed;
      state.shipDeathDate = action.payload.shipDeathDate;
      state.yard = action.payload.yard;
      state.method = action.payload.method;
      state.tattoo = action.payload.tattoo;
      state.embryo = action.payload.embryo;
      state.shipment = action.payload.shipment;
      state.ddEntryDate = action.payload.ddEntryDate;
      state.weight = action.payload.weight;
      state.calfId = action.payload.calfId;
    },
  },
});

export default calvesAllSlice.reducer;
