import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  currencyFormatter,
  dateFormatter,
  filterParams,
  twoDecimalFormatter,
} from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface IInvoicesByDonorState {
  donor?: string;
  date?: Date;
  itemNum?: string;
  description?: string;
  description2?: string;
  unitCost?: number;
  quantity?: number;
  totalCost?: number;
  confidential?: string;
}

export const initialInvoicesByDonorState: IInvoicesByDonorState = {
  donor: undefined,
  date: undefined,
  itemNum: undefined,
  description: undefined,
  description2: undefined,
  unitCost: undefined,
  quantity: undefined,
  totalCost: undefined,
};

const cellClassRules = {
  'cell-pass': (params: any) => params.value >= 0,
  'cell-fail': (params: any) => params.value < 0,
};

export const initialInvoicesByDonorColDefs: ColDefs<IInvoicesByDonorState> = [
  {
    headerName: 'Donor',
    field: 'donor',
    filter: 'agTextColumnFilter',
    initialWidth: 193,
  },
  {
    headerName: 'Date',
    field: 'date',
    filter: 'agDateColumnFilter',
    initialWidth: 105,
    valueFormatter: dateFormatter,
    filterParams,
  },
  {
    headerName: 'Item #',
    field: 'itemNum',
    initialWidth: 107,
  },
  {
    headerName: 'Description',
    field: 'description',
    filter: 'agTextColumnFilter',
    initialWidth: 370,
  },
  {
    headerName: 'Description 2',
    field: 'description2',
    filter: 'agTextColumnFilter',
    initialWidth: 370,
  },
  {
    headerName: 'Cost',
    field: 'unitCost',
    valueFormatter: currencyFormatter,
    cellClass: 'cell-currency',
    cellClassRules,
    filter: 'agNumberColumnFilter',
    initialWidth: 85,
  },
  {
    headerName: 'Quantity',
    field: 'quantity',
    valueFormatter: twoDecimalFormatter,
    cellClass: 'cell-numeric',
    filter: 'agNumberColumnFilter',
    initialWidth: 107,
  },
  {
    headerName: 'Total',
    field: 'totalCost',
    valueFormatter: currencyFormatter,
    cellClass: 'cell-currency',
    cellClassRules,
    filter: 'agNumberColumnFilter',
    initialWidth: 94,
  },
  {
    headerName: 'CONFIDENTIAL',
    field: 'confidential',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
  },
];

export const InvoicesByDonorExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Invoices (By Donor)',
  fileName: 'Invoices (By Donor)',
  columnKeys: [
    'confidential',
    'donor',
    'date',
    'itemNum',
    'description',
    'description2',
    'unitCost',
    'quantity',
    'totalCost',
  ],
};
export const InvoicesByDonorCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Invoices (By Donor)',
  fileName: 'Invoices (By Donor)',
};

export const InvoicesByDonorPdfExport = {
  fileName: 'Invoices (By Donor)',
  columnKeys: [
    'donor',
    'date',
    'itemNum',
    'description',
    'description2',
    'unitCost',
    'quantity',
    'totalCost',
  ],
};

export const InvoicesByDonorSlice = createSlice({
  name: 'InvoicesByDonor',
  initialState: initialInvoicesByDonorState,
  reducers: {
    setInvoicesByDonor: (
      state: IInvoicesByDonorState,
      action: PayloadAction<IInvoicesByDonorState>,
    ) => {
      state.donor = action.payload.donor;
      state.date = action.payload.date;
      state.itemNum = action.payload.itemNum;
      state.description = action.payload.description;
      state.description2 = action.payload.description2;
      state.unitCost = action.payload.unitCost;
      state.quantity = action.payload.quantity;
      state.totalCost = action.payload.totalCost;
    },
  },
});

export const { setInvoicesByDonor } = InvoicesByDonorSlice.actions;

export default InvoicesByDonorSlice.reducer;
