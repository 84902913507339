import { createSlice } from '@reduxjs/toolkit';

export const cardViewSlice = createSlice({
  name: 'isCardView',
  initialState: false, //(document.documentElement.clientWidth || window.innerWidth) <= 576,
  reducers: {
    toggleIsCardView: (state: boolean) => !state,
  },
});

export const { toggleIsCardView } = cardViewSlice.actions;

export default cardViewSlice.reducer;
