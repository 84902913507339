import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface ICalfProceduresForThisWeekState {
  calf?: string;
  procCode?: string;
  procedure?: string;
  description?: string;
  date?: Date;
  time?: string;
  temp?: number;
  weight?: number;
  hipHeight?: number;
  who?: string;
  confidential?: string;
}

export const initialCalfProceduresForThisWeek: ICalfProceduresForThisWeekState =
  {
    calf: undefined,
    procCode: undefined,
    procedure: undefined,
    description: undefined,
    date: undefined,
    time: undefined,
    temp: undefined,
    weight: undefined,
    hipHeight: undefined,
    who: undefined,
  };

export const initialCalfProceduresForThisWeekColDefs: ColDefs<ICalfProceduresForThisWeekState> =
  [
    { headerName: 'Calf', field: 'calf', initialWidth: 79 },
    { headerName: 'Proc Code', field: 'procCode', initialWidth: 113 },
    { headerName: 'Procedure', field: 'procedure', initialWidth: 335 },
    { headerName: 'Description', field: 'description', initialWidth: 439 },
    {
      headerName: 'Date',
      field: 'date',
      initialWidth: 105,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    { headerName: 'Time', field: 'time', initialWidth: 84 },
    {
      headerName: 'Temp',
      field: 'temp',
      initialWidth: 89,
      filter: 'agNumberColumnFilter',
    },
    { headerName: 'Weight', field: 'weight', initialWidth: 97 },
    {
      headerName: 'Hip Height',
      field: 'hipHeight',
      initialWidth: 114,
      filter: 'agNumberColumnFilter',
    },
    { headerName: 'Who', field: 'who', initialWidth: 84 },
    {
      headerName: 'CONFIDENTIAL',
      field: 'confidential',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

export const calfProceduresForThisWeekExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Calf Procedures For This Week',
  fileName: 'Calf Procedures For This Week',
  columnKeys: [
    'confidential',
    'calf',
    'procCode',
    'procedure',
    'description',
    'date',
    'time',
    'temp',
    'weight',
    'hipHeight',
    'who',
  ],
};
export const calfProceduresForThisWeekCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Calf Procedures For This Week',
  fileName: 'Calf Procedures For This Week',
};

export const calfProceduresForThisWeekPdfExport = {
  fileName: 'Calf Procedures For This Week',
  columnKeys: [
    'calf',
    'procCode',
    'procedure',
    'description',
    'date',
    'time',
    'temp',
    'weight',
    'hipHeight',
    'who',
  ],
};

export const calfProceduresForThisWeekSlice = createSlice({
  name: 'calvesAll',
  initialState: initialCalfProceduresForThisWeek,
  reducers: {
    setCalvesAll: (
      state: ICalfProceduresForThisWeekState,
      action: PayloadAction<ICalfProceduresForThisWeekState>,
    ) => {
      state.calf = action.payload.calf;
      state.procCode = action.payload.procCode;
      state.procedure = action.payload.procedure;
      state.description = action.payload.description;
      state.date = action.payload.date;
      state.time = action.payload.time;
      state.temp = action.payload.temp;
      state.weight = action.payload.weight;
      state.hipHeight = action.payload.hipHeight;
      state.who = action.payload.who;
    },
  },
});

export default calfProceduresForThisWeekSlice.reducer;
