import { PrimeIcons } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { useCallback } from 'react';

import { ReportId } from '../services';
import styles from './OpenReport.module.scss';

export function openReport(
  res: { data: ReportId } | { error: any },
  mode: 'inline' | 'attachment',
) {
  if (res && 'data' in res) {
    window.open(
      `${import.meta.env.VITE_API_URL}/reports/report/${
        res.data.reportId
      }?mode=${mode}`,
      '_blank',
    );
  }
}

export function OpenReport({
  query,
}: {
  query(): Promise<{ data: ReportId } | { error: any } | undefined>;
}) {
  const handleClick = useCallback(async () => {
    const res = await query();
    if (res) {
      openReport(res, 'inline');
    }
  }, [query]);

  return (
    <button
      type="button"
      className={classNames(styles['button'], PrimeIcons.FILE_PDF)}
      onClick={handleClick}
    />
  );
}
