import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import { gridOptions } from '../grid/defaults';
import { useAbcFlushReportMutation } from '../services';

export interface IFlushProductionSummaryState {
  flushDate?: Date;
  donor?: string;
  sire?: string;
  breed?: string;
  collected?: number;
  transferred?: number;
  frozen?: number;
  discarded?: number;
  currentUnknown?: number;
  currentOpen?: number;
  currentPregnant?: number;
  pc1Unknown?: number;
  pc1Open?: number;
  pc1Pregnant?: number;
  bulls?: number;
  heifers?: number;
  flushId: string;
  mating?: string;
  confidential?: string;
}

export const initialFlushProductionSummaryState: IFlushProductionSummaryState =
  {
    flushDate: undefined,
    donor: undefined,
    sire: undefined,
    breed: undefined,
    collected: undefined,
    transferred: undefined,
    frozen: undefined,
    discarded: undefined,
    currentUnknown: undefined,
    currentOpen: undefined,
    currentPregnant: undefined,
    pc1Unknown: undefined,
    pc1Open: undefined,
    pc1Pregnant: undefined,
    bulls: undefined,
    heifers: undefined,
    flushId: '',
    mating: undefined,
  };

const AbcPdfRenderer = ({
  data,
}: ICellRendererParams<IFlushProductionSummaryState>) => {
  const menu = useRef<Menu>(null);
  const [report] = useAbcFlushReportMutation();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              if (!data?.mating) {
                return;
              }

              const res = await report({
                mating: data.mating,
                flushId: data.flushId,
              });
              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              if (!data?.mating) {
                return;
              }

              const res = await report({
                mating: data.mating,
                flushId: data.flushId,
              });
              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [data?.flushId, data?.mating, report],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e: any) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

export const initialFlushProductionSummaryColDefs: ColDefs<IFlushProductionSummaryState> =
  [
    {
      headerName: 'ABC Form',
      headerClass: 'header-center',
      cellRenderer: AbcPdfRenderer,
      cellStyle: { display: 'grid', placeContent: 'center' },
      suppressMenu: true,
      sortable: false,
      initialWidth: 83,
    },
    {
      headerName: 'Flush Date',
      field: 'flushDate',
      initialWidth: 117,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    { headerName: 'Donor', field: 'donor', initialWidth: 182 },
    {
      headerName: 'Sire',
      field: 'sire',
      initialWidth: 132,
      filter: 'agNumberColumnFilter',
    },
    { headerName: 'Breed', field: 'breed', initialWidth: 101 },
    {
      headerName: 'Collected',
      field: 'collected',
      initialWidth: 111,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Transferred',
      field: 'transferred',
      initialWidth: 121,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Frozen',
      field: 'frozen',
      initialWidth: 96,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Discarded',
      field: 'discarded',
      initialWidth: 114,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Pending',
      field: 'currentUnknown',
      initialWidth: 105,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Open',
      field: 'currentOpen',
      initialWidth: 90,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Pregnant',
      field: 'currentPregnant',
      initialWidth: 110,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Bulls',
      field: 'bulls',
      initialWidth: 86,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Heifers',
      field: 'heifers',
      initialWidth: 99,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'CONFIDENTIAL',
      field: 'confidential',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

export const flushProdSummExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Flush Production Summary',
  fileName: 'Flush Production Summary',
  columnKeys: [
    'confidential',
    'flushDate',
    'donor',
    'sire',
    'breed',
    'collected',
    'transferred',
    'frozen',
    'discarded',
    'currentUnknown',
    'currentOpen',
    'currentPregnant',
    'bulls',
    'heifers',
  ],
};
export const flushProdSummCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Flush Production Summary',
  fileName: 'Flush Production Summary',
};

export const flushProdSummPdfExport = {
  fileName: 'Flush Production Summary',
  columnKeys: [
    'flushDate',
    'donor',
    'sire',
    'breed',
    'collected',
    'transferred',
    'frozen',
    'discarded',
    'currentUnknown',
    'currentOpen',
    'currentPregnant',
    'bulls',
    'heifers',
  ],
};

export const flushProductionSummarySlice = createSlice({
  name: 'flushProductionSummary',
  initialState: initialFlushProductionSummaryState,
  reducers: {
    setFlushProductionSummary: (
      state: any,
      action: PayloadAction<IFlushProductionSummaryState>,
    ) => {
      state.flushDate = action.payload.flushDate;
      state.donor = action.payload.donor;
      state.sire = action.payload.sire;
      state.breed = action.payload.breed;
      state.collected = action.payload.collected;
      state.transferred = action.payload.transferred;
      state.frozen = action.payload.frozen;
      state.discarded = action.payload.discarded;
      state.currentUnknown = action.payload.currentUnknown;
      state.currentOpen = action.payload.currentOpen;
      state.currentPregnant = action.payload.currentPregnant;
      state.bulls = action.payload.bulls;
      state.heifers = action.payload.heifers;
    },
  },
});

export const { setFlushProductionSummary } =
  flushProductionSummarySlice.actions;

export default flushProductionSummarySlice.reducer;
