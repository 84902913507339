import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs, ICellRendererParams } from '../components/ag-grid';
import { openReport } from '../components/OpenReport';
import PcStatusCellRenderer from '../features/production-summary/PcStatusCellRenderer';
import { gridOptions } from '../grid/defaults';
import {
  useAbcIvfFertReportMutation,
  useAbcIvfPoolReportMutation,
} from '../services';

export interface IIvfProductionSummaryState {
  aspirationId?: string;
  aspirationPoolId?: string;
  breed?: string;
  bulls?: number;
  cleavedPct?: number;
  clientFroze?: number;
  clientTransfer?: number;
  clientDiscard?: number;
  colCode?: string;
  d6Total?: number;
  donor?: string;
  fertDate?: string | undefined;
  fertilizationId?: string;
  g1?: number;
  g2?: number;
  g3?: number;
  heifers?: number;
  id?: string;
  marginal?: number;
  mating?: string;
  pcStatus?: string;
  poolName?: string;
  pregOpen?: number;
  pregPend?: number;
  pregPreg?: number;
  quality?: number;
  rowNo?: number;
  sire?: string;
  sireId?: string;
  totalCleaved?: number;
  totalDiscarded?: number;
  totalIvc?: number;
  totalOoc?: number;
  totalShipped?: number;
  totalTogFrozen?: number;
  totalTogTransferred?: number;
  semenType?: string;
  degenerates?: number;
  shippedBillable?: number;
  donorLocation?: string;
  freshShipmentName?: string;
  confidential?: string;
}

const AbcPdfRenderer = ({
  data,
}: ICellRendererParams<IIvfProductionSummaryState>) => {
  const menu = useRef<Menu>(null);

  const [abcIvfPool] = useAbcIvfPoolReportMutation();
  const [abcIvfFert] = useAbcIvfFertReportMutation();

  const items = useMemo(
    () => [
      {
        label: 'Options',
        items: [
          {
            label: 'View',
            icon: 'pi pi-eye',
            async command() {
              let res;

              if (!data || !data.mating) {
                return;
              }

              if (data.aspirationPoolId && data.fertilizationId) {
                res = await abcIvfPool({
                  fertilizationId: data.fertilizationId,
                  aspirationPoolId: data.aspirationPoolId,
                  mating: data.mating,
                });
                openReport(res, 'inline');
                return;
              }

              if (!data.aspirationId || !data.fertilizationId) {
                return;
              }

              res = await abcIvfFert({
                aspirationId: data.aspirationId,
                fertilizationId: data.fertilizationId,
                mating: data.mating,
              });

              openReport(res, 'inline');
            },
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            async command() {
              let res;

              if (!data || !data.mating) {
                return;
              }

              if (data.aspirationPoolId && data.fertilizationId) {
                res = await abcIvfPool({
                  fertilizationId: data.fertilizationId,
                  aspirationPoolId: data.aspirationPoolId,
                  mating: data.mating,
                });
                openReport(res, 'attachment');
                return;
              }

              if (!data.aspirationId || !data.fertilizationId) {
                return;
              }

              res = await abcIvfFert({
                aspirationId: data.aspirationId,
                fertilizationId: data.fertilizationId,
                mating: data.mating,
              });

              openReport(res, 'attachment');
            },
          },
        ],
      },
    ],
    [abcIvfFert, abcIvfPool, data],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <i
        className="pi pi-file-pdf"
        onClick={(e) => menu.current?.toggle(e)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
    </>
  );
};

export const initialIvfProductionSummaryColDefs: ColDefs<IIvfProductionSummaryState> =
  [
    {
      headerName: 'ABC Form',
      headerClass: 'header-center',
      cellRenderer: AbcPdfRenderer,
      cellStyle: { display: 'grid', placeContent: 'center' },
      suppressMenu: true,
      sortable: false,
      initialWidth: 83,
    },
    {
      headerName: 'Aspiration Date',
      field: 'fertDate',
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
      initialWidth: 105,
    },
    { headerName: 'Donor Name', field: 'donor', initialWidth: 145 },
    { headerName: 'Pool Name', field: 'poolName', initialWidth: 110 },
    { headerName: 'Sire Name', field: 'sire', initialWidth: 124 },
    { headerName: 'Sire ID/NAAB', field: 'sireId', initialWidth: 120 },
    { headerName: 'Breed', field: 'breed', initialWidth: 80 },
    { headerName: 'Collection Code', field: 'colCode', initialWidth: 103 },
    { headerName: 'Semen Type', field: 'semenType', initialWidth: 110 },
    { headerName: 'Donor Location', field: 'donorLocation', initialWidth: 110 },
    {
      headerName: 'Fresh Shipment Name',
      field: 'freshShipmentName',
      initialWidth: 327,
    },
    {
      headerName: 'Client Transfer',
      field: 'clientTransfer',
      initialWidth: 87,
    },
    { headerName: 'Client Froze', field: 'clientFroze', initialWidth: 75 },
    { headerName: 'Client Discard', field: 'clientDiscard', initialWidth: 85 },
    {
      headerName: 'Total Oocytes',
      field: 'totalOoc',
      filter: 'agNumberColumnFilter',
      initialWidth: 85,
    },
    {
      headerName: 'Total IVC',
      field: 'totalIvc',
      filter: 'agNumberColumnFilter',
      initialWidth: 74,
    },
    {
      headerName: 'Total Cleaved',
      field: 'totalCleaved',
      filter: 'agNumberColumnFilter',
      initialWidth: 98,
    },
    {
      headerName: 'Cleaved Percentage',
      field: 'cleavedPct',
      filter: 'agNumberColumnFilter',
      initialWidth: 106,
    },
    {
      headerName: 'Predicted Good',
      field: 'quality',
      filter: 'agNumberColumnFilter',
      initialWidth: 97,
    },
    {
      headerName: 'Predicted Low',
      field: 'marginal',
      filter: 'agNumberColumnFilter',
      initialWidth: 100,
    },
    {
      headerName: 'Day 6 Total',
      field: 'd6Total',
      filter: 'agNumberColumnFilter',
      initialWidth: 77,
    },
    {
      headerName: 'TOG Transferred',
      field: 'totalTogTransferred',
      filter: 'agNumberColumnFilter',
      initialWidth: 105,
    },
    {
      headerName: 'TOG Frozen',
      field: 'totalTogFrozen',
      filter: 'agNumberColumnFilter',
      initialWidth: 83,
    },
    {
      headerName: 'TOG Discard',
      field: 'totalDiscarded',
      filter: 'agNumberColumnFilter',
      initialWidth: 87,
    },
    {
      headerName: 'Degenerates',
      field: 'degenerates',
      filter: 'agNumberColumnFilter',
      initialWidth: 128,
    },
    {
      headerName: 'Total Shipped',
      field: 'totalShipped',
      filter: 'agNumberColumnFilter',
      initialWidth: 98,
    },
    {
      headerName: 'Billable Shipped',
      field: 'shippedBillable',
      filter: 'agNumberColumnFilter',
      initialWidth: 98,
    },
    {
      headerName: 'Grade 1',
      field: 'g1',
      filter: 'agNumberColumnFilter',
      initialWidth: 89,
    },
    {
      headerName: 'Grade 2',
      field: 'g2',
      filter: 'agNumberColumnFilter',
      initialWidth: 87,
    },
    {
      headerName: 'Grade 3',
      field: 'g3',
      filter: 'agNumberColumnFilter',
      initialWidth: 85,
    },
    {
      headerName: 'Pregancy Status',
      field: 'pcStatus',
      filter: 'agNumberColumnFilter',
      cellRenderer: PcStatusCellRenderer,
      cellStyle: { display: 'grid', placeContent: 'center' },
      initialWidth: 103,
    },
    { headerName: 'Pending', field: 'pregPend', hide: true },
    { headerName: 'Open', field: 'pregOpen', hide: true },
    { headerName: 'Pregnant', field: 'pregPreg', hide: true },
    { headerName: 'Bulls', field: 'bulls', hide: true },
    { headerName: 'Heifers', field: 'heifers', hide: true },
    {
      headerName: 'CONFIDENTIAL',
      field: 'confidential',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

export const ivfProdSummExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'IVF Production Summary',
  fileName: 'IVF Production Summary',
  columnKeys: [
    'confidential',
    'fertDate',
    'donor',
    'poolName',
    'sire',
    'sireId',
    'breed',
    'colCode',
    'semenType',
    'donorLocation',
    'freshShipmentName',
    'totalOoc',
    'totalIvc',
    'totalCleaved',
    'cleavedPct',
    'quality',
    'marginal',
    'd6Total',
    'totalTogTransferred',
    'totalTogFrozen',
    'totalDiscarded',
    'degenerates',
    'totalShipped',
    'shippedBillable',
    'g1',
    'g2',
    'g3',
  ],
};
export const ivfProdSummCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'IVF Production Summary',
  fileName: 'IVF Production Summary',
};

export const ivfProdSummPdfExport = {
  fileName: 'IVF Production Summary',
  columnKeys: [
    'fertDate',
    'donor',
    'poolName',
    'sire',
    'sireId',
    'breed',
    'colCode',
    'semenType',
    'donorLocation',
    'freshShipmentName',
    'totalOoc',
    'totalIvc',
    'totalCleaved',
    'cleavedPct',
    'quality',
    'marginal',
    'd6Total',
    'totalTogTransferred',
    'totalTogFrozen',
    'totalDiscarded',
    'degenerates',
    'totalShipped',
    'shippedBillable',
    'g1',
    'g2',
    'g3',
  ],
};
