import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dateFormatter, filterParams } from '@transova/rtk';

import { ColDefs } from '../components/ag-grid';
import { gridOptions } from '../grid/defaults';

export interface ICalfProceduresForMonthState {
  birthDate?: Date;
  calf?: string;
  ownerId?: string;
  sex?: string;
  procCode?: string;
  procedure?: string;
  description?: string;
  date?: Date;
  time?: string;
  temp?: number;
  weight?: number;
  drug?: string;
  hipHeight?: number;
  who?: string;
  confidential?: string;
}

export const initialCalfProceduresForMonth: ICalfProceduresForMonthState = {
  birthDate: undefined,
  calf: undefined,
  ownerId: undefined,
  sex: undefined,
  procCode: undefined,
  procedure: undefined,
  description: undefined,
  date: undefined,
  time: undefined,
  temp: undefined,
  weight: undefined,
  drug: undefined,
  hipHeight: undefined,
  who: undefined,
};

export const initialCalfProceduresForMonthColDefs: ColDefs<ICalfProceduresForMonthState> =
  [
    {
      headerName: 'Birth Date',
      field: 'birthDate',
      initialWidth: 112,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    { headerName: 'Calf', field: 'calf', initialWidth: 79 },
    { headerName: 'Owner Id', field: 'ownerId', initialWidth: 111 },
    { headerName: 'Sex', field: 'sex', initialWidth: 82 },
    { headerName: 'Proc Code', field: 'procCode', initialWidth: 113 },
    { headerName: 'Procedure', field: 'procedure', initialWidth: 338 },
    { headerName: 'Description', field: 'description', initialWidth: 450 },
    {
      headerName: 'Date',
      field: 'date',
      initialWidth: 105,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams,
    },
    { headerName: 'Time', field: 'time', initialWidth: 84 },
    {
      headerName: 'Temp',
      field: 'temp',
      initialWidth: 89,
      filter: 'agNumberColumnFilter',
    },
    { headerName: 'Weight', field: 'weight', initialWidth: 97 },
    { headerName: 'Drug', field: 'drug', initialWidth: 177 },
    {
      headerName: 'Hip Height',
      field: 'hipHeight',
      initialWidth: 114,
      filter: 'agNumberColumnFilter',
    },
    { headerName: 'Who', field: 'who', initialWidth: 85 },
    {
      headerName: 'CONFIDENTIAL',
      field: 'confidential',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

export const calfProceduresForMonthExcelExport = {
  ...gridOptions.defaultExcelExportParams,
  sheetName: 'Calf Procedures For Month',
  fileName: 'Calf Procedures For Month',
  columnKeys: [
    'confidential',
    'birthDate',
    'calf',
    'ownerId',
    'sex',
    'procCode',
    'procedure',
    'description',
    'date',
    'time',
    'temp',
    'weight',
    'drug',
    'hipHeight',
    'who',
  ],
};
export const calfProceduresForMonthCsvExport = {
  ...gridOptions.defaultCsvExportParams,
  sheetName: 'Calf Procedures For Month',
  fileName: 'Calf Procedures For Month',
};

export const calfProceduresForMonthPdfExport = {
  fileName: 'Calf Procedures For Month',
  columnKeys: [
    'birthDate',
    'calf',
    'ownerId',
    'sex',
    'procCode',
    'procedure',
    'description',
    'date',
    'time',
    'temp',
    'weight',
    'drug',
    'hipHeight',
    'who',
  ],
};

export const calfProceduresForMonthSlice = createSlice({
  name: 'calvesAll',
  initialState: initialCalfProceduresForMonth,
  reducers: {
    setCalvesAll: (
      state: ICalfProceduresForMonthState,
      action: PayloadAction<ICalfProceduresForMonthState>,
    ) => {
      state.birthDate = action.payload.birthDate;
      state.calf = action.payload.calf;
      state.ownerId = action.payload.ownerId;
      state.sex = action.payload.sex;
      state.procCode = action.payload.procCode;
      state.procedure = action.payload.procedure;
      state.description = action.payload.description;
      state.date = action.payload.date;
      state.time = action.payload.time;
      state.temp = action.payload.temp;
      state.weight = action.payload.weight;
      state.drug = action.payload.drug;
      state.hipHeight = action.payload.hipHeight;
      state.who = action.payload.who;
    },
  },
});

export default calfProceduresForMonthSlice.reducer;
