import * as ReactDOM from 'react-dom/client';
import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { routes } from '@generouted/react-router/lazy';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';

import { store } from './app/store';

const httpLink = new HttpLink({
  uri: import.meta.env.PROD
    ? '/graphql'
    : `${import.meta.env.VITE_API}/graphql`,
  credentials: 'include',
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (!import.meta.env.PROD) {
        console.error('[GraphQL Error]', err);
      }
    });
  }

  if (networkError) {
    if (!import.meta.env.PROD) {
      console.error('[GraphQL Error]', networkError);
    }
  }
});
const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: !import.meta.env.PROD,
});

const allRoutes: RouteObject[] = [
  {
    children: routes,
    ErrorBoundary() {
      const error = useRouteError() as Error | undefined;

      useEffect(() => {
        if (!error) {
          return;
        }

        console.error(error);

        if (
          error.message
            .toLowerCase()
            .includes('failed to fetch dynamically imported module')
        ) {
          // if a new version of the app is released, old files might no longer be available
          // reloading allows loading new files
          // https://mitchgavan.com/code-splitting-react-safely/
          // https://dimaip.github.io/2020/04/25/deploying-apps-with-code-splitting/
          window.location.reload();
        }
      }, [error]);

      return null;
    },
  },
];

function Routes() {
  return <RouterProvider router={createBrowserRouter(allRoutes)} />;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  </Provider>,
);
